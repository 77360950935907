module.exports = [{
      plugin: require('/codebuild/output/src032324606/src/github.com/t04glovern/nathanglover-gatsby-portfolio/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Open Sans"]}},
    },{
      plugin: require('/codebuild/output/src032324606/src/github.com/t04glovern/nathanglover-gatsby-portfolio/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nathan Glover Portfolio","short_name":"Nathan Glover Portfolio","start_url":"/","background_color":"#FFFFFF","theme_color":"#7c37ad","display":"minimal-ui","icon":"media/icon.png"},
    },{
      plugin: require('/codebuild/output/src032324606/src/github.com/t04glovern/nathanglover-gatsby-portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src032324606/src/github.com/t04glovern/nathanglover-gatsby-portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-71336829-21"},
    }]
